import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei'; // Import GLTF loader
import { useFileContext } from '../../../context/FileContext'; // Import useFileContext
import './UploadModal.css'
export default function UploadModal({ show, handleClose }) {
  const [fileType, setFileType] = useState('');
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [modelURL, setModelURL] = useState(null); // Store the URL for 3D model

  const { addFile } = useFileContext(); // Get addFile from context

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
    setFile(null);
    setUploaded(false);
    setModelURL(null); // Reset model URL when file type changes
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);

    // Generate URL for model preview if it's a 3D model
    if (fileType === '3dModel') {
      const objectURL = URL.createObjectURL(uploadedFile);
      setModelURL(objectURL);
    }
  };

  const handleUpload = () => {
    if (file) {
      setUploaded(true);

      // Add file to context after uploading
      addFile({
        name: file.name,
        type: fileType,
        file: file,
        uploadedAt: new Date().toLocaleString(), // Adding timestamp
      });
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileType('');
    setUploaded(false);
    setModelURL(null);
  };

  const renderPreview = () => {
    if (!file) return null;

    if (fileType === 'image') {
      const imageURL = URL.createObjectURL(file);
      return <img src={imageURL} alt="Uploaded Image" style={{ width: '100%' }} />;
    }

    if (fileType === 'video') {
      const videoURL = URL.createObjectURL(file);
      return <video src={videoURL} controls style={{ width: '100%' }} />;
    }

    // For 3D Model, render it using Three.js if the modelURL is available
    if (fileType === '3dModel' && modelURL) {
      return (
        <Canvas style={{ width: '100%', height: '400px' }}>
          <OrbitControls enableZoom={true} />
          <ambientLight intensity={0.5} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} />
          <Model modelURL={modelURL} /> {/* Render 3D model */}
        </Canvas>
      );
    }

    return null;
  };

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="engineering-upload-modal">
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {uploaded ? (
          <div>
            <h5>Uploaded {fileType === 'image' ? 'Image' : fileType === 'video' ? 'Video' : '3D Model'} Preview:</h5>
            {renderPreview()}
            <Button variant="danger" className="mt-3" onClick={handleRemoveFile}>
              Remove File
            </Button>
          </div>
        ) : (
          <Form>
            <Form.Group controlId="fileType">
              <Form.Label>Select File Type</Form.Label>
              <Form.Control as="select" value={fileType} onChange={handleFileTypeChange}>
                <option value="">-- Select --</option>
                <option value="image">Image</option>
                <option value="3dModel">3D Model</option>
                <option value="video">Video</option>
              </Form.Control>
            </Form.Group>

            {fileType && (
              <Form.Group controlId="fileUpload" className="mt-3">
                <Form.Label>
                  {fileType === 'image' && 'Select Image'}
                  {fileType === '3dModel' && 'Select 3D Model (.glb or .obj)'}
                  {fileType === 'video' && 'Select Video'}
                </Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant={uploaded ? 'primary' : 'secondary'} onClick={handleClose}>
          {uploaded ? 'Upload' : 'Cancel'}
        </Button>
        {!uploaded && (
          <Button variant="primary" onClick={handleUpload} disabled={!file}>
            Upload
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

// Separate component to load and render the 3D model
function Model({ modelURL }) {
  const { scene } = useGLTF(modelURL); // Load the GLTF model using the URL

  return <primitive object={scene} scale={1} />;
}
