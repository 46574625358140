import React, { useState } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import { useFileContext } from '../../../context/FileContext';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei'; // Import GLTF loader
import './ViewModal.css';

export default function ViewModal({ show, handleClose }) {
  const { files } = useFileContext(); // Get the list of files from context
  const [selectedFile, setSelectedFile] = useState(null); // State to store selected file for viewing
  const [showFileModal, setShowFileModal] = useState(false); // State to control file preview modal

  // Helper function to render file preview in table
  const renderPreview = (file) => {
    if (!file) return null;

    if (file.type === 'image') {
      const imageURL = URL.createObjectURL(file.file);
      return <img src={imageURL} alt="Uploaded Image" style={{ width: '100px', height: '100px' }} />;
    }

    if (file.type === 'video') {
      const videoURL = URL.createObjectURL(file.file);
      return <video src={videoURL} controls style={{ width: '100px', height: '100px' }} />;
    }

    if (file.type === '3dModel') {
      const modelURL = URL.createObjectURL(file.file);
      return (
        <Canvas style={{ width: '100px', height: '100px' }}>
          <OrbitControls enableZoom={true} />
          <ambientLight intensity={10} />
          <spotLight position={[15, 15, 15]} angle={0.15} penumbra={1} />
          <pointLight position={[20, 20, 20]} />
          <Model modelURL={modelURL} /> {/* Render 3D model */}
        </Canvas>
      );
    }

    return null;
  };

  // Function to handle opening the file modal
  const handleViewFile = (file) => {
    setSelectedFile(file);
    setShowFileModal(true);
  };
  
  // Function to render the file inside the modal
  const renderFileInModal = (file) => {
    if (!file) return null;

    if (file.type === 'image') {
      const imageURL = URL.createObjectURL(file.file);
      return <img src={imageURL} alt="Uploaded Image" style={{ width: '100%', height: 'auto' }} />;
    }

    if (file.type === 'video') {
      const videoURL = URL.createObjectURL(file.file);
      return <video src={videoURL} controls style={{ width: '100%', height: 'auto' }} />;
    }

    if (file.type === '3dModel') {
      const modelURL = URL.createObjectURL(file.file);
      return (
        <Canvas style={{ width: '100%', height: '400px' }}>
          <OrbitControls enableZoom={true} />
          <ambientLight intensity={10} />
          <spotLight position={[15, 15, 15]} angle={0.15} penumbra={1} />
          <pointLight position={[20, 20, 20]} />
          <Model modelURL={modelURL} /> {/* Render 3D model */}
        </Canvas>
      );
    }

    return null;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg" dialogClassName="engineering-view-modal">
        <Modal.Header closeButton>
          <Modal.Title>Uploaded Files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {files.length > 0 ? (
            <Table responsive className="assets-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Uploaded At</th>
                  <th>Preview</th> {/* New column for file preview */}
                  <th>View</th> {/* Column for view button */}
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr key={index}>
                    <td>{file.name}</td>
                    <td>{file.type}</td>
                    <td>{file.uploadedAt}</td>
                    <td>{renderPreview(file)}</td> {/* Render preview based on file type */}
                    <td>
                      <Button variant="primary" onClick={() => handleViewFile(file)}>
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No files uploaded yet.</p>
          )}
        </Modal.Body>
      </Modal>

      {/* Modal to show the selected file */}
      <Modal show={showFileModal} onHide={() => setShowFileModal(false)} centered size="lg" dialogClassName="engineering-preview-modal">
        <Modal.Header closeButton>
          <Modal.Title>File Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedFile && renderFileInModal(selectedFile)}</Modal.Body>
      </Modal>
    </>
  );
}

// Separate component to load and render the 3D model
function Model({ modelURL }) {
  const { scene } = useGLTF(modelURL); // Load the GLTF model using the URL

  return <primitive object={scene} scale={3} />; // Apply static scale
}
