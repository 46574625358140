import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // For React Router v6
import './Login.css'; // Custom CSS for additional styling
import WhiteLogo from '../../assets/login/images/metakosmos_white_logo.png';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({}); // To store errors
  const navigate = useNavigate(); // For navigation

  const handleLoginClick = (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validate email
    if (!email) {
      newErrors.email = 'Email is required';
    }

    // Validate password
    if (!password) {
      newErrors.password = 'Password is required';
    }

    // If there are any errors, set the error state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Clear any previous error messages
      setErrors({});

      // Navigate to the home route
      navigate('/admin/home');
    }
  };

  return (
    <div className="login-container">
      <div className="background-image"></div> {/* Isolated background image */}
      <div className="background-overlay"></div> {/* Overlay with gradient */}
      <div className="shiny-glow"></div> {/* Shiny glow effect */}
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="text-center">
            <img src={WhiteLogo} alt="Metakosmos Logo" className="logo mb-4" /> {/* Replace with your actual logo */}
            <p className="tagline text-white">Unified Receptive Productive System</p>
            <Form className="login-form">
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label className="text-white">Login</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="example@metakosmos.au"
                  className="input-field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Set email value
                  isInvalid={!!errors.email}
                />
                {/* Email error message */}
                {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="mb-4">
                <Form.Label className="text-white">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="••••••••"
                  className="input-field"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} // Set password value
                  isInvalid={!!errors.password}
                />
                {/* Password error message */}
                {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
              </Form.Group>

              {/* Customized Button */}
              <div className="login-button-container" onClick={handleLoginClick}>
                <img
                  src={require('../../assets/login/images/image-5.png')}
                  alt="Enter Button Background"
                  className="login-button-image"
                />
                <span className="login-button-text">Enter</span>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
