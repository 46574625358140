import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './SecondLogin.css'; // Custom CSS for additional styling
import WhiteLogo from '../../assets/login/images/metakosmos_white_logo.png';

export default function SecondLogin() {
  return (
    <div className="second-login-container">
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="text-center">
            <img src={WhiteLogo} alt="Metakosmos Logo" className="logo mb-4" />
            <p className="tagline text-white">Unified Receptive Productive System</p>
            <Form className="login-form">
            <Row>
            <Col md={10}>
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label className="text-white">Login</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="example@metakosmos.au"
                  className="input-field"
                />
              </Form.Group>
              
                <Form.Group controlId="formBasicPassword" className="mb-4">
                <Form.Label className="text-white">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="••••••••"
                  className="input-field"
                />
              </Form.Group>
                </Col>
                <Col md={2} className='mt-5'>
                <span  type="submit" className="second-login-button">
                <span className="button-text"></span>
              </span>
                </Col>
              </Row>
              
              <p className="forgot-password mt-3">
                <a href="/" className="text-muted">Forgot Password?</a>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
