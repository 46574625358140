import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TopNavbar from '../TopNavbar/TopNavbar';
import Sidebar from '../Sidebar/Sidebar';
import routes from '../../routes';
import './AdminLayout.css';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

function AdminLayout() {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route path={prop.path} element={prop.component} key={key} exact />
                );
            }
            return null;
        });
    };

    return (
        <div className="admin-layout">
            <TopNavbar />
            <div className="layout-wrapper">
                <Sidebar />
                <div className="main-content container-fluid">
                    <Routes>
                        {getRoutes(routes)}
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;
