import React from 'react';
import './Sidebar.css';
import { NavLink, Link, useLocation } from "react-router-dom";
function Sidebar() {

    const location = useLocation();

    const activeStyle = {
      background: 'linear-gradient(90deg, rgba(42, 42, 42, 0.9), rgba(107, 107, 107, 0.9))',
      color: '#ccc'
    };
    return (
        <div className="sidebar" 
        //  style={{borderRight:'3px solid #6d6d6d',borderTopRightRadius:'15px'}}
         >
            <ul>
                <li  style={location.pathname === '/admin/home' ? activeStyle : {}}>
                  <NavLink className="nav-link" activeClassName="active" to="home">
                   <i className="fas fa-home"></i> Home
                  </NavLink>
                </li>
                <li style={location.pathname === '/admin/calendar' ? activeStyle : {}}>
                  <NavLink  className="nav-link" activeClassName="active" to="calendar">
                    <i className="fas fa-calendar"></i> Calendar
                  </NavLink>
                </li>
                <li><a href="#calls"><i className="fas fa-phone"></i> Calls</a></li>
                <li><a href="#messages"><i className="fas fa-envelope"></i> Messages</a></li>
                <li style={location.pathname === '/admin/requirments' ? activeStyle : {}}>
                  <NavLink className="nav-link" activeClassName="active" to="requirments">
                    <i className="fas fa-list"></i> Requirements
                    </NavLink>
                </li>
                <li style={location.pathname === '/admin/design' ? activeStyle : {}}>
                   <NavLink className="nav-link" activeClassName="active" to="design">
                    <i className="fas fa-pencil"></i> Design
                    </NavLink>
                </li>
                <li style={location.pathname === '/admin/engineering' ? activeStyle : {}}>
                  <NavLink className="nav-link" activeClassName="active" to="engineering">
                    <i className="fas fa-cog"></i> Engineering
                  </NavLink>
                </li>
                <li style={location.pathname === '/admin/production-routing' ? activeStyle : {}}>
                  <NavLink className="nav-link" activeClassName="active" to="production-routing">
                    <i className="fas fa-cubes"></i> Production
                  </NavLink>
                </li>
                <li style={location.pathname === '/admin/quality-testing' ? activeStyle : {}}>
                  <NavLink className="nav-link" activeClassName="active" to="quality-testing">
                    <i className="fas fa-check"></i> Quality & Testing
                    </NavLink>
                </li>
                <li style={location.pathname === '/admin/maintenance' ? activeStyle : {}}>
                   <NavLink className="nav-link" activeClassName="active" to="maintenance">
                     <i className="fas fa-wrench"></i> Maintenance
                    </NavLink>
                </li>
                <li style={location.pathname === '/admin/account' ? activeStyle : {}}>
                   <NavLink className="nav-link" activeClassName="active" to="account">
                     <i className="fas fa-user"></i> Account
                    </NavLink>
                </li>
                <li><a href="#settings"><i className="fas fa-settings"></i> Settings</a></li>
            </ul>
        </div>
    );
}

export default Sidebar;
