import React from 'react';
import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';
import './Account.css'; // For custom styles
import Schedule from '../../common/Schedule/Schedule';

export default function Account() {
  return (
    <Container fluid className="account-container">
      {/* Background Section */}
      <div className="account-background-image"></div>
        <Row className="profile-section">
          <Col md={12} className="profile-details">
          <Image
              src={require('../../../assets/admin/account/image-9.png')} // Replace with Tim Cook's image URL
              roundedCircle
              className="profile-image"
            />
          </Col>
          </Row>
          <Row className='profile-name'>
          <Col md={6}>
          <h2>Tim Cook</h2>
            <a href="mailto:TimCook@metakosmos.au.edu" className="email-link">TimCook@metakosmos.au.edu</a>
            <p className="team-manager">Team Manager</p>
            <Row>
                <Col md={6}>
                <Form.Control type="text" placeholder="Send a message" className="message-input" />
                </Col>
                <Col md={6}>
                <div className="contact-icons float-end">

                <img className='contact-icon' src={require('../../../assets/admin/account/image 25.png')} />
                <img className='contact-icon' src={require('../../../assets/admin/account/image 44.png')} />
                {/* <Button variant="link" className="contact-icon">📞</Button> */}
                {/* <Button variant="link" className="contact-icon">✉️</Button> */}
                </div>
                </Col>
            </Row>
          </Col>
          <Col md={6} className="interests-career">
          <div className="vertical-line"></div>
          <Row>
            <Col md={12}>
            <div className="interests">
              <h5>Interests</h5>
              <p>Bouldering, Running, Comedy Movies, Cooking</p>
            </div>
            </Col>
            <Col md={12}>
            <div className="career">
              <h5>Career</h5>
              <p>
              I have a strong background in leading cross functional teams in the aerospace industry. My expertise lies in project management, strategic planning, and optimizing team performance. My goal is to empower individuals and align team efforts with organizational success, ensuring the seamless execution of high-impact projects in dynamic environments.
              </p>
            </div>
            </Col>
          </Row>
          </Col>
        </Row>
        <Row>
            <Col md={12}>
            <Schedule />
            </Col>
        </Row>
    </Container>
  );
}
