import React, { useState, useEffect } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css'; // Custom styles for this component

const localizer = momentLocalizer(moment);

const Calendar = () => {
  const [events, setEvents] = useState([]);

  // Mock real-time events, replace with actual API calls for real-time data
  useEffect(() => {
    const fetchEvents = async () => {
      const eventList = [
        {
          title: 'New Year\'s Day',
          start: new Date(2024, 0, 1), // January 1, 2024
          end: new Date(2024, 0, 1),
        },
        {
          title: 'Independence Day',
          start: new Date(2024, 6, 4), // July 4, 2024
          end: new Date(2024, 6, 4),
        },
        {
          title: 'Meeting with team',
          start: new Date(), // Current date and time
          end: new Date(),
        },
        // Add more mock events or fetch from an API
      ];
      setEvents(eventList);
    };
    fetchEvents();
  }, []);

  return (
    <div className="calendar-container">
      <h3>Your Calendar</h3>
      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultDate={new Date()}
        style={{ height: '80vh' }}
        views={['month']}
        components={{
          month: {
            dateHeader: ({ label, date }) => {
              const today = new Date();
              const isToday = moment(today).isSame(date, 'day');
              return (
                <span style={{ color: isToday ? '#ff6347' : '#fff' }}>{label}</span>
              );
            },
          },
        }}
      />
    </div>
  );
};

export default Calendar;
